import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';

import PageNotFound from 'common/components/error/PageNotFound';
import * as routesConstants from 'common/routes/routes-constants';
import { ExtractorsMain, FirstExtractorRedirect, SelectedExtractor, ShortExtractorUrlRedirect } from 'features/extractors';
import { PortalContainer, PortalsMain } from 'features/portal';
import { FirstReportRedirect, NewReportPage, NewReportSettingsPage, SelectedReport } from 'features/reports';
import { ReportsLayout } from 'features/reports/layout/ReportsLayout';
import { AccountMain } from 'features/user/account';

const V2Main = lazy(() => import('features/extractor-builder-v2/ExtractorBuilderV2Main'));

const Lightning = lazy(() => import('app/lightning-old/containers/AppContainer'));

const OverviewMain = lazy(() => import('features/overview/OverviewMain'));

export const appRoutes: RouteObject[] = [
  {
    element: <Navigate to={routesConstants.DASH_PAGE_URL} />,
    path: '/',
  },
  {
    path: routesConstants.DASH_PAGE_URL,
    children: [
      {
        element: <OverviewMain />,
        index: true,
      },
      {
        path: routesConstants.PORTALS_PAGE_URL,
        children: [
          {
            element: <PortalsMain />,
            index: true,
          },
          {
            element: <PortalContainer />,
            path: routesConstants.MATCH_PORTAL_GUID,
          },
        ],
      },
      {
        element: <ExtractorsMain />,
        path: routesConstants.EXTRACTORS_PAGE_ID,
        children: [
          {
            element: <FirstExtractorRedirect />,
            index: true,
          },
          {
            element: <SelectedExtractor />,
            path: `${routesConstants.MATCH_EXTRACTOR_GUID}/*`,
          },
        ],
      },
      {
        element: <Outlet />,
        path: routesConstants.REPORTS_PAGE_ID,
        children: [
          {
            element: <FirstReportRedirect />,
            index: true,
          },
          {
            element: <NewReportPage />,
            path: 'new',
          },
          {
            element: <NewReportSettingsPage />,
            path: 'new/settings',
          },
          {
            element: (
              <ReportsLayout showHeader>
                <SelectedReport />
              </ReportsLayout>
            ),
            path: `${routesConstants.MATCH_GUID}/*`,
          },
        ],
      },
      {
        element: <AccountMain />,
        path: `${routesConstants.ACCOUNT_PAGE_ID}/*`,
      },
    ],
  },
  {
    element: <V2Main />,
    path: `${routesConstants.V2_PAGE_ID}/*`,
  },
  {
    element: <ShortExtractorUrlRedirect />,
    path: `${routesConstants.SHORT_EXTRACTOR_PAGE_URL}/*`,
  },
  {
    element: <Lightning />,
    path: routesConstants.RESULTS_PAGE_URL,
  },
  {
    element: <Lightning />,
    path: routesConstants.BROWSE_PAGE_URL,
  },
  {
    element: <PageNotFound />,
    path: '*',
  },
];
