import type { JSX } from 'react';

import { isPresent } from '@import-io/typeguards';
import { PlanType } from '@import-io/types';
import type { UserSubscriptionData } from '@import-io/types/user-subscription-types';

import { AppLink } from 'common/components/AppLink';
import Coin from 'common/components/icons/Coin';
import type { SegmentConfig } from 'common/components/SegmentedProgress';
import { SegmentedProgress } from 'common/components/SegmentedProgress';
import { cn } from 'common/utils/cn';
import SubscriptionUpdateButton from 'features/user/subscription/components/SubscriptionUpdateButton';
import { useFetchSubscription } from 'features/user/subscription/subscription-hooks';
import { canPerformQuery, getPlanTitle, getSubscriptionText } from 'features/user/subscription/subscription-utils';

import styles from './SubscriptionWidget.module.less';

const defaultSubscription: Partial<UserSubscriptionData> = {
  expiry: 0,
  maxUsage: 0,
  overage: 0,
  planCode: PlanType.UNVERIFIED,
  trial: false,
  usage: 0,
};

const segmentsConfig: SegmentConfig[] = [
  {
    key: 'usage',
    className: 'bg-purple-brand',
  },
  {
    key: 'overage',
    className: 'bg-warn',
  },
];

const SubscriptionWidget = (): JSX.Element => {
  const { data: subscription = null } = useFetchSubscription();
  const isPresentSubscription = isPresent(subscription);
  const canQuery = canPerformQuery(subscription);
  const subText = isPresentSubscription ? getSubscriptionText(subscription) : '';
  const title = getPlanTitle(subscription);
  const { usage = 0, maxUsage = 0 } = isPresentSubscription ? subscription : defaultSubscription;
  const isOverage = usage > maxUsage;

  const usageTotal = Math.max(usage, maxUsage);
  const overageUsage = usage - maxUsage > 0 ? usage - maxUsage : 0;
  const nonOverageUsage = usage - overageUsage;

  return (
    <div className={`text-center p-4 text-sidebar-foreground`}>
      <div>
        <span className={styles.floatLeft}>
          QUERIES <Coin />
        </span>
        <div className={styles.floatRight}>
          <span
            className={cn({
              'text-warn': isOverage,
            })}
            data-test-id="sidebar-current-extractor-usage"
          >
            {usage} /
          </span>
          <span data-test-id="sidebar-extractor-max-usage">&nbsp;{maxUsage}</span>
        </div>
      </div>
      <SegmentedProgress
        className="bg-card/10"
        segments={segmentsConfig}
        total={usageTotal}
        values={{ usage: nonOverageUsage, overage: overageUsage }}
      />

      <h5 className="mt-4 mb-0">
        <AppLink className="hover:underline hover:text-white" to="/dash/account/billing">
          {canQuery ? title : 'Plan limit reached'}
        </AppLink>
      </h5>

      {Boolean(subText) ? (
        <div>
          <p className={styles.subText}>{subText}</p>
        </div>
      ) : null}

      <SubscriptionUpdateButton className={`full-width ${styles.upgradeButton}`} />
    </div>
  );
};

export default SubscriptionWidget;
