import { isPresent } from '@import-io/typeguards';
import type { InfiniteData } from '@tanstack/query-core';

import { LIST_KEY } from 'common/hooks/entity-manager/construct-list-query-key';
import type { CreateEntityManagerParams } from 'common/hooks/entity-manager/types';
import { queryClient } from 'common/query/query-constants';

type CreateUpdateEntityInCacheParams<T extends object> = Pick<CreateEntityManagerParams<T>, 'rootKey' | 'idField'>;

/**
 * Update an item in the cache.
 * @param config - Configuration for identifying the list and the unique identifier.
 */

export const createUpdateEntityInCache = <T extends object>(config: CreateUpdateEntityInCacheParams<T>) => {
  const { rootKey, idField } = config;

  return (update: { id: string; data: Partial<T> }) => {
    const id = update.id;
    const updateData = update.data;

    const initialByIdQuery = queryClient.getQueryData<T>([rootKey, id]);
    const initialListQueries = queryClient.getQueriesData<InfiniteData<T[]>>({ queryKey: [rootKey, LIST_KEY] });

    // Update single item query if it exists
    queryClient.setQueryData<T>([rootKey, id], (existing) => {
      if (isPresent(existing)) {
        return { ...existing, ...updateData };
      }
      return existing;
    });

    // Update the list in the infinite queries
    queryClient.setQueriesData<InfiniteData<T[]>>(
      {
        queryKey: [rootKey, LIST_KEY],
      },
      (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          pages: oldData.pages.map((page) =>
            page.map((item) => {
              if (item[idField] === id) {
                return { ...item, ...updateData };
              }
              return item;
            }),
          ),
        };
      },
    );

    return {
      rollback: () => {
        queryClient.setQueryData<T>([rootKey, id], initialByIdQuery);
        initialListQueries.forEach(([qKey, qData]) => {
          queryClient.setQueryData(qKey, qData);
        });
      },
    };
  };
};
