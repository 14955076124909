import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import type { ResponseError } from '@import-io/js-sdk';
import { objectStoreApi, ObjectStoreQueryRequestBuilder } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { Report, ReportType } from '@import-io/types/report-types';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query/src/types';

export const useFetchFirstReport = (): UseQueryResult<Report | undefined | null, ResponseError> => {
  return useQuery({
    queryKey: ['firstReport'],
    queryFn: async () => {
      const query = new ObjectStoreQueryRequestBuilder()
        .setPageNumber(1)
        .setPageLimit(1)
        .setSortDesc(true)
        .setShowArchived(false)
        .setShowMine(true)
        .build();

      const results = await objectStoreApi.report.query(query);

      return results[0] ?? null;
    },
  });
};
