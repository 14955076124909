import type { JSX, PropsWithChildren } from 'react';

import type { PageProps } from 'common/components/layout/layout-types';
import PageLayout from 'common/components/layout/PageLayout';
import ReportsHeader from 'features/reports/layout/header/ReportsHeader';
import { ReportsSidebar } from 'features/reports/layout/ReportsSidebar';
import DisabledFeature from 'features/user/subscription/components/DisabledFeature';
import { useSubscriptionFeatureFlags } from 'features/user/subscription/subscription-hooks';

export const ReportsLayout = ({ children, showHeader = false }: PropsWithChildren<PageProps>): JSX.Element => {
  const { canUseAnyReport } = useSubscriptionFeatureFlags();
  const header = showHeader ? <ReportsHeader /> : undefined;
  return (
    <>
      {!canUseAnyReport && <DisabledFeature message="Your account does not have access to reporting features." />}
      <PageLayout header={header} sidebar={<ReportsSidebar />} title="reports">
        {children}
      </PageLayout>
    </>
  );
};
