import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EyeFilled from '@ant-design/icons/EyeFilled';
import PlayCircleFilled from '@ant-design/icons/PlayCircleFilled';
import QuestionCircleFilled from '@ant-design/icons/QuestionCircleFilled';
import SettingFilled from '@ant-design/icons/SettingFilled';
import { endpoints } from '@import-io/js-sdk';
import { isGuid, isPresent } from '@import-io/typeguards';
import type { Report } from '@import-io/types/report-types';
import { Role } from '@import-io/types/user-types';
import { useQueryClient } from '@tanstack/react-query';
import Modal from 'antd/lib/modal';
import { toast } from 'sonner';

import { history } from 'app/app-history';
import type { AppDispatch } from 'app/app-types';
import { toggleAutoPublish } from 'app/dash-old/actions/reports';
import type { PageHeaderAction, PageHeaderLinkProps } from 'common/components/layout/header/layout-header-types';
import { useAppModal } from 'common/components/modal/AppModalContext';
import { useHasRole } from 'common/hooks/use-has-role';
import { useIsOwner } from 'common/hooks/use-is-owner';
import { HISTORY_PAGE_ID, REPORTS_PAGE_URL } from 'common/routes/routes-constants';
import { useCrawlRuns } from 'features/extractors/hooks/use-extractor-crawl-runs';
import { useDeleteReport } from 'features/reports/common/hooks/use-reports-hooks';
import { GET_REPORT_QUERY_KEY } from 'features/reports/common/reports-constants';
import type { ExtendedReport } from 'features/reports/common/reports-types';
import { getReportHistoryUrl, getReportSettingsUrl } from 'features/reports/common/reports-utils';
import { useRunReport } from 'features/reports/history/hooks/use-run-report';
import { REPORT_LIST_QUERY_KEY } from 'features/reports/list/report-list-constants';

export const useAfterDelete = (reportId: string): ((arg: any) => void) => {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    const reports = queryClient.getQueryData<Report[]>(REPORT_LIST_QUERY_KEY) || [];
    const newReports = reports.filter((r) => r.guid !== reportId);
    queryClient.setQueryData(REPORT_LIST_QUERY_KEY, newReports);

    await queryClient.invalidateQueries({ queryKey: [GET_REPORT_QUERY_KEY, reportId], refetchType: 'none' });
    void toast.success('Report successfully deleted');
    const url = newReports.length > 0 ? getReportHistoryUrl(newReports[0]!.guid) : REPORTS_PAGE_URL;
    history.push(url);
  }, [reportId, queryClient]);
};

const deleteError = 'Delete report error';

export const useDeleteAction = (isOwner: boolean): PageHeaderAction => {
  const deleteReport = useDeleteReport();
  const modal = useAppModal();

  const reportId = useParams()['guid']!;
  const confirmDeleteReport = useCallback(() => {
    void modal.confirm({
      cancelText: 'No',
      okText: 'Yes, delete my report',
      onOk: () => deleteReport.mutate({ id: reportId }),
      title: 'This action cannot be undone. Are you sure?',
      type: 'warning',
    });
  }, [deleteReport, modal, reportId]);

  return useMemo(
    () => ({
      disabled: !isOwner || deleteReport.isPending,
      icon: <DeleteOutlined />,
      label: 'Delete',
      variant: 'destructive',
      onClick: confirmDeleteReport,
    }),
    [confirmDeleteReport, deleteReport, isOwner],
  );
};

export const useReportHeaderActions = (report: ExtendedReport): PageHeaderAction[] => {
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const { data: crawlRunsPages } = useCrawlRuns(report.extractorId);
  const extractorRuns = useMemo(() => {
    return crawlRunsPages?.pages?.[0] ?? [];
  }, [crawlRunsPages]);
  const { autoPublish, togglingAutoPublish = false, type } = report;
  const isOwner = useIsOwner(report);

  const runReport = useRunReport();
  const canRun = isOwner && !runReport.isPending && extractorRuns.length >= 2;
  const canAutoPublish = isOwner && !togglingAutoPublish;
  const isHistory = pathname.includes(HISTORY_PAGE_ID);

  const togglePublish = useCallback(() => dispatch(toggleAutoPublish()), [dispatch]);
  const deleteAction = useDeleteAction(isOwner);

  return useMemo<PageHeaderAction[]>(
    () => [
      ...(isHistory
        ? [
            {
              disabled: !canRun,
              icon: <PlayCircleFilled />,
              label: runReport.isPending ? 'Starting...' : 'Run Report',
              onClick: () => (isPresent(report?.guid) ? runReport.mutate({ reportId: report.guid }) : null),
              type: 'primary',
            } as PageHeaderAction,
          ]
        : []),
      {
        disabled: !canAutoPublish,
        label: autoPublish ? 'Disable auto publishing' : 'Enable auto publishing',
        onClick: togglePublish,
        tooltip: autoPublish ? 'Stop sharing this report to portal' : 'Automatically share this report to portal',
      },
      deleteAction,
    ],
    [autoPublish, canAutoPublish, canRun, deleteAction, isHistory, report.guid, runReport, togglePublish],
  );
};

const reportSupportUrl = `https://hades.${endpoints.rootDomain}/store/report`;

export const useReportHeaderTabs = (reportId?: string): PageHeaderLinkProps[] => {
  const isSupport = useHasRole(Role.SUPPORT);
  return useMemo<PageHeaderLinkProps[]>(() => {
    if (!isGuid(reportId)) {
      return [];
    }

    const defaultTabs: PageHeaderLinkProps[] = [
      {
        icon: <EyeFilled />,
        label: `run history`,
        to: getReportHistoryUrl(reportId),
      },
      {
        icon: <SettingFilled />,
        label: 'settings',
        to: getReportSettingsUrl(reportId),
      },
    ];
    return isSupport
      ? [
          ...defaultTabs,
          {
            href: `${reportSupportUrl}/${reportId}`,
            icon: <QuestionCircleFilled />,
            label: 'support',
            target: '_blank',
          },
        ]
      : defaultTabs;
  }, [isSupport, reportId]);
};
