import type { JSX } from 'react';
import { useCallback, useMemo } from 'react';

import DiffFilled from '@ant-design/icons/DiffFilled';
import RedoOutlined from '@ant-design/icons/RedoOutlined';
import { isPresent } from '@import-io/typeguards';
import { ReportType } from '@import-io/types/report-types';

import SidebarDropdown from 'app/dash-old/components/common/SidebarDropdown';
import { REPORT_TYPES } from 'app/dash-old/lib/constants/reports';
import type { SortByProperty } from 'common/common-types';
import { AppLink } from 'common/components/AppLink';
import PageSidebar from 'common/components/layout/PageSidebar';
import { PageSidebarInput } from 'common/components/layout/PageSidebarInput';
import { DataList } from 'common/components/list/DataList';
import type { DataListColumnComponentProps, DataListColumnConfig } from 'common/components/list/types';
import { Button } from 'common/components/shadcn/button';
import { LoadingSpinner } from 'common/components/shadcn/loading-spinner';
import { Tooltip, TooltipContent, TooltipTrigger } from 'common/components/shadcn/tooltip';
import { NEW_REPORT_PAGE_URL } from 'common/routes/routes-constants';
import { useCreateReportDisabled } from 'features/reports/common/hooks/use-create-report-disabled';
import { useCurrentReportTab } from 'features/reports/common/hooks/use-current-report-tab';
import { useReportsList, useReportsQueryParams } from 'features/reports/common/hooks/use-reports-hooks';
import type { ExtendedReport, ReportTypeFilter } from 'features/reports/common/reports-types';
import { getReportUrl } from 'features/reports/common/reports-utils';
import { selectSubscriptionQueryData } from 'features/user/subscription/subscription-query';

const ReportListNameColumn = ({ item }: DataListColumnComponentProps<ExtendedReport>): JSX.Element => {
  const tab = useCurrentReportTab();
  return (
    <Tooltip delayDuration={300}>
      <TooltipTrigger asChild>
        <AppLink
          className={
            '[&.active]:bg-card/20 [&.active]:font-medium [&.active]:text-sidebar-foreground hover:bg-card/10 transition-none px-2 hover:text-sidebar-foreground text-sidebar-foreground/80 flex items-center w-full h-full whitespace-nowrap overflow-ellipsis rounded'
          }
          preserveSearchParams
          to={getReportUrl(item.guid, tab)}
        >
          <span className="block w-full overflow-hidden overflow-ellipsis">{item.name}</span>
          <span className="flex-shrink-0">{item.type === 'CRAWL_DIFF' ? <DiffFilled /> : null}</span>
        </AppLink>
      </TooltipTrigger>
      <TooltipContent side={'right'}>{item.name}</TooltipContent>
    </Tooltip>
  );
};

const REPORT_LIST_COLS: DataListColumnConfig<ExtendedReport>[] = [
  {
    key: 'name',
    component: ReportListNameColumn,
    size: { span: 1 },
  },
];

const initialTypeFilter: ReportTypeFilter = {
  [ReportType.CRAWL_DIFF]: true,
  [ReportType.CRAWL_REPORT]: true,
};

export const ReportsSidebar = (): JSX.Element => {
  const {
    value: listParams,
    filter,
    setFilter,
    search,
    setSearch,
    sortProperty,
    setSortProperty,
    sortDirection,
    setSortDirection,
  } = useReportsQueryParams();

  const typeFilter: Record<string, boolean> = useMemo(() => {
    if (!isPresent(filter.type)) {
      return initialTypeFilter;
    }
    return ((filter.type ?? []) as string[]).reduce((acc, type) => {
      acc[type] = true;
      return acc;
    }, {});
  }, [filter]);

  const toggleTypeFilter = useCallback(
    (checked: boolean, type: ReportType) => {
      const newTypes = { ...typeFilter, [type]: checked };
      const selectedTypes = Object.keys(newTypes).filter((t) => Boolean(newTypes[t]));
      void setFilter({ type: selectedTypes });
    },
    [setFilter, typeFilter],
  );

  const handleSortChange = useCallback(
    (property: SortByProperty<ExtendedReport>, desc: boolean) => {
      void setSortProperty(property);
      void setSortDirection(desc ? 'desc' : 'asc');
    },
    [setSortDirection, setSortProperty],
  );

  const { items, isPending, fetchNextPage, allFetched, isFetchingNextPage, refetch } = useReportsList(listParams);

  const subscription = selectSubscriptionQueryData();
  const features = subscription?.features;
  const availableReportTypes = useMemo(
    () => (isPresent(features) ? REPORT_TYPES.filter((report) => features.includes(report.features.permission)) : []),
    [features],
  );

  const createReportDisabled = useCreateReportDisabled();

  return (
    <PageSidebar showSubscription title="Reports">
      <div className="w-full p-2 overflow-hidden">
        <div className="flex flex-col items-center gap-2 w-full">
          <Button
            asChild
            className="w-full hover:text-primary-foreground uppercase text-xs"
            disabled={createReportDisabled}
            size="lg"
          >
            <AppLink to={NEW_REPORT_PAGE_URL}>New Report</AppLink>
          </Button>
          <div className="flex w-full gap-2">
            <PageSidebarInput
              onChange={setSearch}
              placeholder="Search.."
              suffix={
                <SidebarDropdown
                  availableReports={availableReportTypes}
                  filterDisplayItemTypes={typeFilter}
                  isDescending={sortDirection === 'desc'}
                  isReports
                  sortBy={handleSortChange}
                  sortingByProperty={sortProperty}
                  toggleTypeFilter={toggleTypeFilter}
                />
              }
              value={search!}
            />
            <Button
              className="flex-shrink-0 text-sidebar-foreground border-sidebar-foreground/30 bg-transparent hover:bg-primary/40"
              disabled={isPending}
              onClick={() => refetch()}
              size="icon"
              variant="outline"
            >
              <RedoOutlined />
            </Button>
          </div>
        </div>
      </div>
      <DataList
        allLoaded={allFetched}
        allLoadedNode={<div className="h-10" />}
        className="px-2 border-solid border border-gray-border-inverse border-x-0"
        columns={REPORT_LIST_COLS}
        columnsGap={0}
        itemClassName="text-sm bg-transparent p-0 rounded !shadow-none border-transparent justify-start"
        itemGap={4}
        itemHeight={40}
        itemKey={'guid'}
        items={items}
        listPaddingY={8}
        loading={isPending || isFetchingNextPage}
        loadingNode={
          <div className="h-full flex items-center justify-center">
            <LoadingSpinner className="opacity-70" variant="card" />
          </div>
        }
        onLoadMore={fetchNextPage}
      />
    </PageSidebar>
  );
};
