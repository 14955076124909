import type { JSX } from 'react';

import { isPresent } from '@import-io/typeguards';
import type { ButtonSize } from 'antd/lib/button';
import type { ButtonType } from 'antd/lib/button/buttonHelpers';
import { Loader2 } from 'lucide-react';

import type { CustomButtonProps } from 'common/components/buttons/buttons-types';
import type { ButtonProps } from 'common/components/shadcn/button';
import { Button } from 'common/components/shadcn/button';
import { TooltipWrapper } from 'common/components/TooltipWrapper';

const variantsMap: Record<ButtonType | 'danger', ButtonProps['variant']> = {
  ['default']: 'outline',
  ['primary']: 'default',
  ['dashed']: 'outline',
  ['link']: 'link',
  ['text']: 'ghost',
  ['danger']: 'destructive',
};

const sizesMap: Record<NonNullable<ButtonSize | 'icon'>, ButtonProps['size']> = {
  ['small']: 'sm',
  ['middle']: 'default',
  ['large']: 'lg',
  ['icon']: 'icon',
};

export const CustomButton = ({
  children,
  className = '',
  disabled = false,
  type = 'default',
  size = 'middle',
  icon,
  tooltip,
  loading = false,
  variant,
  ...restProps
}: CustomButtonProps): JSX.Element => {
  return (
    <TooltipWrapper {...tooltip}>
      <Button
        className={className}
        disabled={disabled || Boolean(loading)}
        size={sizesMap[size] ?? 'default'}
        variant={variant ?? variantsMap[type]}
        {...restProps}
      >
        <>
          {Boolean(loading) ? <Loader2 className="animate-spin" /> : null}
          {isPresent(icon) ? icon : null}
          {children}
        </>
      </Button>
    </TooltipWrapper>
  );
};
