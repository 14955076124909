import type { JSX } from 'react';

import { isPresent } from '@import-io/typeguards';

import { selectSubscriptionQueryData } from '../subscription-query';

import type { DisabledFeatureProps } from './disabled-feature-types';
import UpgradeMessage from './UpgradeMessage';

const DisabledFeature = ({ content, flag, message, showUpgrade = true }: DisabledFeatureProps): JSX.Element | null => {
  const subscription = selectSubscriptionQueryData();

  if (!isPresent(subscription) || (isPresent(flag) && subscription.features.indexOf(flag) === -1)) {
    return <UpgradeMessage message={message} showUpgrade={showUpgrade} />;
  }

  return isPresent(content) ? <div>{content}</div> : null;
};

export default DisabledFeature;
