import type { PropsWithChildren, ReactNode } from 'react';
import { useCallback, useState, useEffect, useRef, memo } from 'react';

import { isPresent } from '@import-io/typeguards';

import { cn } from 'common/utils/cn';

import { Tooltip, TooltipContent, TooltipTrigger } from './shadcn/tooltip';

type UseFollowMouseParams = {
  enabled?: boolean;
};
function useFollowMouse<T extends HTMLElement = any>({ enabled = false }: UseFollowMouseParams) {
  const triggerRef = useRef<T>(null);
  const [offsets, setOffsets] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    const target = e.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    setOffsets({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  }, []);

  useEffect(() => {
    if (!enabled || !isPresent(triggerRef.current)) {
      return;
    }

    const abortController = new AbortController();

    triggerRef.current.addEventListener(
      'mouseenter',
      () => {
        triggerRef.current!.addEventListener('mousemove', handleMouseMove, { signal: abortController.signal });
        setIsHovering(true);
      },
      { signal: abortController.signal },
    );
    triggerRef.current.addEventListener(
      'mouseleave',
      () => {
        triggerRef.current!.removeEventListener('mousemove', handleMouseMove);
        setIsHovering(false);
      },
      { signal: abortController.signal },
    );

    return () => {
      abortController.abort();
    };
  }, [enabled, handleMouseMove, triggerRef]);

  return { triggerRef: triggerRef, isHovering: isHovering, ...offsets };
}

export type TooltipPlacement = 'top' | 'bottom' | 'left' | 'right';
export type TooltipWrapperProps = PropsWithChildren & {
  readonly contentClass?: string;
  readonly disabled?: boolean;
  readonly disabledTitle?: ReactNode | (() => ReactNode);
  readonly title?: ReactNode | (() => ReactNode);
  readonly delay?: number;
  readonly placement?: TooltipPlacement;
  readonly followMouse?: boolean;
  readonly asChild?: boolean;
};

export const TooltipWrapper = memo(
  ({
    children,
    contentClass,
    disabled = false,
    title,
    disabledTitle,
    placement,
    delay,
    followMouse = false,
    asChild = true,
  }: TooltipWrapperProps) => {
    const { triggerRef, x, y, isHovering } = useFollowMouse({ enabled: followMouse });
    const tooltip = disabled ? disabledTitle : title;
    const tooltipContent = isPresent(tooltip) ? (typeof tooltip === 'function' ? tooltip() : tooltip) : null;

    return isPresent(tooltip) ? (
      <Tooltip delayDuration={delay ?? 150}>
        <TooltipTrigger asChild={asChild} ref={triggerRef}>
          {disabled ? <div>{children}</div> : children}
        </TooltipTrigger>
        <TooltipContent
          align={followMouse ? 'start' : 'center'}
          alignOffset={y - 10}
          className={cn(contentClass, { '!pointer-events-none !user-select-none': followMouse })}
          hidden={Boolean(followMouse && !isHovering)}
          hideWhenDetached
          side={placement}
          sideOffset={-x + 10}
        >
          {tooltipContent}
        </TooltipContent>
      </Tooltip>
    ) : (
      children
    );
  },
);

TooltipWrapper.displayName = 'TooltipWrapper';
