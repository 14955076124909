import type { JSX } from 'react';
import { useMemo } from 'react';

import Space from 'antd/lib/space';

import { CustomButton } from 'common/components/buttons/CustomButton';
import type { TooltipWrapperProps } from 'common/components/TooltipWrapper';

import type { PageHeaderAction, PageHeaderActionsProps } from './layout-header-types';
import styles from './PageHeaderActions.module.less';

const Action = ({ label, disabledTooltip, tooltip, ...restProps }: PageHeaderAction) => {
  const tooltipProps = useMemo<TooltipWrapperProps>(
    () => ({
      title: tooltip,
      disabledTitle: disabledTooltip,
      placement: 'bottom',
    }),
    [disabledTooltip, tooltip],
  );

  return (
    <CustomButton className={styles.button} tooltip={tooltipProps} {...restProps}>
      {label}
    </CustomButton>
  );
};

const PageHeaderActions = ({ items }: PageHeaderActionsProps): JSX.Element => (
  <Space className="flex justify-end">
    {items.map(({ label, tooltip, ...restProps }) => (
      <Action key={label} label={label} tooltip={tooltip} {...restProps} />
    ))}
  </Space>
);

export default PageHeaderActions;
