import { createGetByIdInCache } from 'common/hooks/entity-manager/get-by-id-in-cache';
import { createUpdateEntityInCache } from 'common/hooks/entity-manager/update-entity-in-cache';
import { createUseById } from 'common/hooks/entity-manager/use-by-id';
import { createUseCreate } from 'common/hooks/entity-manager/use-create';
import { createUseDeleteById } from 'common/hooks/entity-manager/use-delete-by-id';
import { createInvalidateList, createUseList } from 'common/hooks/entity-manager/use-list';
import { createUseUpdateById } from 'common/hooks/entity-manager/use-update-by-id';

import type { CreateEntityManagerParams, CreateEntityManagerResult } from './types';

/**
 * Higher-Order Function to create entity-specific hooks and utilities.
 * @param params - Configuration for the specific entity.
 * @returns An object containing the entity-specific hook and cache utilities.
 */
export function createEntityManager<TEntity extends object>(
  params: CreateEntityManagerParams<TEntity>,
): CreateEntityManagerResult<TEntity> {
  const { rootKey, idField, searchField, crudConfig, defaultListParams } = params;

  return {
    useList: createUseList({
      searchField: searchField,
      rootKey: rootKey,
      idField: idField,
      defaultListParams: defaultListParams,
      queryFn: crudConfig.getList,
    }),
    useById: createUseById({
      rootKey: rootKey,
      idField: idField,
      queryFn: crudConfig.getById,
    }),
    useUpdateById: createUseUpdateById({
      rootKey: rootKey,
      idField: idField,
      queryFn: crudConfig.updateById,
    }),
    useDeleteById: createUseDeleteById({
      rootKey: rootKey,
      idField: idField,
      queryFn: crudConfig.deleteById,
    }),
    useCreate: createUseCreate({
      rootKey: rootKey,
      idField: idField,
      queryFn: crudConfig.create,
    }),
    invalidateList: createInvalidateList(rootKey),
    updateByIdInCache: createUpdateEntityInCache(params),
    getByIdInCache: createGetByIdInCache(params),
  };
}
