export function deduplicateBy<T>(array: T[], keyFn: (obj: T) => string | number): T[] {
  const seenKeys = new Set<string | number>();
  const result: T[] = [];

  for (const item of array) {
    const key = keyFn(item);
    if (!seenKeys.has(key)) {
      seenKeys.add(key);
      result.push(item);
    }
  }

  return result;
}
