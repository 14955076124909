import type { ChangeEventHandler, JSX, ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';

import throttle from 'lodash/throttle';
import { SearchIcon } from 'lucide-react';

import type { InputProps } from 'common/components/shadcn/input';
import { Input } from 'common/components/shadcn/input';
import { cn } from 'common/utils/cn';

type PageSidebarInputProps = Omit<InputProps, 'onChange'> & {
  readonly throttleTime?: number;
  readonly suffix?: ReactNode;
  readonly onChange?: (value: string) => void;
};

export const PageSidebarInput = ({
  throttleTime = 500,
  value = '',
  suffix,
  onChange,
  placeholder,
  className,
}: PageSidebarInputProps): JSX.Element => {
  const [innerValue, setInnerValue] = useState(value);
  const onChangeDelayed = useMemo(() => throttle((val) => onChange?.(val), throttleTime), [throttleTime, onChange]);

  const onChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target }) => {
      const newValue = target.value;
      setInnerValue(newValue);
      onChangeDelayed(newValue);
    },
    [onChangeDelayed],
  );

  return (
    <div className={cn('w-full relative', className)}>
      <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center justify-center ps-3 text-muted-foreground/80 peer-disabled:opacity-50">
        <SearchIcon aria-hidden="true" size={16} strokeWidth={2} />
      </div>
      <Input
        className="peer ps-9 pe-9 border border-card/20 bg-transparent focus-visible:ring-0"
        onChange={onChangeHandler}
        placeholder={placeholder}
        value={innerValue}
      />
      <div className="cursor-pointer h-full absolute inset-y-0 end-0 flex items-center justify-center pe-3 text-sidebar-foreground peer-disabled:opacity-50">
        {suffix}
      </div>
    </div>
  );
};
