import type { JSX, PropsWithChildren } from 'react';

import type { TabsActionsHeaderProps } from 'common/components/layout/header/layout-header-types';
import PageHeaderActions from 'common/components/layout/header/PageHeaderActions';

export const ActionsHeader = ({ actions = [], children }: PropsWithChildren<TabsActionsHeaderProps>): JSX.Element | null => (
  <div className="relative w-full h-full z-50 flex flex-col">
    {actions.length ? (
      <div className="w-full h-full flex-1 flex items-center justify-between gap-4">
        <div className="flex h-full items-center flex-1 flex-shrink overflow-hidden">{children}</div>
        <div>
          <PageHeaderActions items={actions} />
        </div>
      </div>
    ) : (
      children
    )}
  </div>
);
