import { useMemo } from 'react';

import ArrowsAltOutlined from '@ant-design/icons/ArrowsAltOutlined';
import BorderOutlined from '@ant-design/icons/BorderOutlined';
import DiffFilled from '@ant-design/icons/DiffFilled';
import FilterFilled from '@ant-design/icons/FilterFilled';
import Checkbox from 'antd/lib/checkbox';
import Menu from 'antd/lib/menu';
import Space from 'antd/lib/space';
import PropTypes from 'prop-types';

import MenuItem from 'common/components/menu/MenuItem';
import ButtonPopover from 'common/components/popover/ButtonPopover';
import { useHandleOpenChange } from 'common/hooks/use-handle-open-change';

import styles from './SidebarDropdown.module.less';

const iconProps = {
  width: 20,
};

const getIcon = (iconType) => {
  switch (iconType) {
    case 'ArrowsAltOutlined':
      return <ArrowsAltOutlined {...iconProps} />;
    case 'DiffFilled':
      return <DiffFilled {...iconProps} />;
    case 'BorderOutlined':
      return <BorderOutlined {...iconProps} />;
    default:
      return undefined;
  }
};

// TODO: this component requires a deep refactoring
const SidebarDropdown = (props) => {
  const [isOpen, handleOpenChange, handleClose] = useHandleOpenChange();
  const {
    availableReports,
    className = '',
    filterDisplayItemTypes,
    isDescending,
    isReports,
    sortBy,
    sortingByProperty,
    toggleTypeFilter,
  } = props;

  const items = useMemo(() => {
    let menuItems = [];
    // TODO: component should not be connected with reports directly
    if (isReports) {
      menuItems = availableReports.map((type) => ({
        key: type.features.name,
        label: (
          <Checkbox
            checked={filterDisplayItemTypes[type.slug]}
            className={styles.checkbox}
            key={type.slug}
            onChange={(e) => toggleTypeFilter(e.target.checked, type.slug)}
          >
            <Space align="center">
              {getIcon(type.features.icon)}
              {type.features.name}
            </Space>
          </Checkbox>
        ),
      }));
      menuItems.push({
        type: 'divider',
      });
    }

    menuItems = menuItems.concat([
      {
        key: 'createdRecent',
        label: (
          <MenuItem
            active={sortingByProperty === 'meta_created_at' && isDescending}
            onClick={() => sortBy('meta_created_at', true)}
            text="Created: Recent First"
          />
        ),
      },
      {
        key: 'createdOldest',
        label: (
          <MenuItem
            active={sortingByProperty === 'meta_created_at' && !isDescending}
            onClick={() => sortBy('meta_created_at', false)}
            text="Created: Oldest First"
          />
        ),
      },
      {
        key: 'updatedLastFirst',
        label: (
          <MenuItem
            active={sortingByProperty === 'meta_updated_at' && isDescending}
            onClick={() => sortBy('meta_updated_at', true)}
            text="Updated: Recent First"
          />
        ),
      },
      {
        key: 'updatedFirstLast',
        label: (
          <MenuItem
            active={sortingByProperty === 'meta_updated_at' && !isDescending}
            onClick={() => sortBy('meta_updated_at', false)}
            text="Updated: Oldest First"
          />
        ),
      },
      {
        key: 'nameAz',
        label: (
          <MenuItem
            active={sortingByProperty === 'name' && !isDescending}
            onClick={() => sortBy('name', false)}
            text="Name: A to Z"
          />
        ),
      },
      {
        key: 'nameZa',
        label: (
          <MenuItem
            active={sortingByProperty === 'name' && isDescending}
            onClick={() => sortBy('name', true)}
            text="Name: Z to A"
          />
        ),
      },
    ]);
    return menuItems;
  }, [availableReports, filterDisplayItemTypes, isDescending, isReports, sortBy, sortingByProperty, toggleTypeFilter]);

  return (
    <ButtonPopover
      button={<FilterFilled size="large" />}
      className={`${className}`}
      content={<Menu className={styles.menu} items={items} onSelect={handleClose} />}
      onOpenChange={handleOpenChange}
      open={isOpen}
      placement="bottomRight"
    />
  );
};

SidebarDropdown.propTypes = {
  availableReports: PropTypes.array,
  className: PropTypes.string,
  filterDisplayItemTypes: PropTypes.object,
  isDescending: PropTypes.bool.isRequired,
  isReports: PropTypes.bool,
  sortBy: PropTypes.func.isRequired,
  sortingByProperty: PropTypes.string.isRequired,
  toggleTypeFilter: PropTypes.func,
};

export default SidebarDropdown;
