import { useParams } from 'react-router-dom';

import type { NullableString } from '@import-io/types';

import { EXTRACTOR_GUID } from 'common/routes/routes-constants';

export const useSelectedExtractorGuid = (): NullableString => {
  const { [EXTRACTOR_GUID]: guid = null } = useParams<Record<string, string>>();
  return guid;
};
