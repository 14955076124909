/* eslint-disable */
// @ts-nocheck
import * as React from 'react';

import { cn } from 'common/utils/cn';
import { isPresent } from '@import-io/typeguards';

export interface InputProps extends React.ComponentProps<'input'> {
  error?: string | null; // Add error property
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, error, ...props }, ref) => {
  return (
    <>
      <input
        type={type}
        className={cn(
          'invalid:border-destructive aria-[invalid=true]:border-destructive flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
          error && 'border-destructive', // Add error border color
          className,
        )}
        ref={ref}
        {...props}
      />
      {isPresent(error) ? <p className="text-destructive -mt-1">{error}</p> : null}
    </>
  );
});
Input.displayName = 'Input';

export { Input };
