import { isPresent } from '@import-io/typeguards';
import type { InfiniteData } from '@tanstack/query-core';
import { useMutation } from '@tanstack/react-query';

import type { CreateEntityManagerParams, CrudConfig, UseDeleteByIdFn } from 'common/hooks/entity-manager/types';
import { useDeleteEntityInCache } from 'common/hooks/entity-manager/use-delete-entity-in-cache';
import { queryClient } from 'common/query/query-constants';

export type CreateUseDeleteByIdParams<T extends object> = {
  queryFn: CrudConfig<T>['deleteById'];
} & Pick<CreateEntityManagerParams<T>, 'rootKey' | 'idField'>;

export const createUseDeleteById = <T extends object>(config: CreateUseDeleteByIdParams<T>): UseDeleteByIdFn => {
  const { rootKey, queryFn } = config;

  return function useDeleteById(options = {}) {
    const { onSuccess, onError, onMutate, ...other } = options;
    const deleteInCache = useDeleteEntityInCache<T>(config);
    return useMutation({
      ...other,
      mutationFn: ({ id }: { id: string }) => {
        console.log('useDeleteById', id);
        return isPresent(queryFn) ? queryFn(id) : Promise.reject('Not implemented');
      },
      onMutate: async (params) => {
        onMutate?.(params);
        await queryClient.cancelQueries({
          queryKey: [rootKey],
        });

        // Snapshot the previous value of the entities list
        const previousData = queryClient.getQueriesData<InfiniteData<T[]>>({
          queryKey: [rootKey],
        });

        deleteInCache(params.id);
        // Return a context object with the previous data to use for rollback in case of error
        return { previousData: previousData };
      },
      onSuccess: (result, params, context) => {
        onSuccess?.(result, params, context);
        deleteInCache(params.id);
      },
      onError: (e, params, context) => {
        onError?.(e, params, context);
        console.error(e);
      },
    });
  };
};
