import type { JSX, PropsWithChildren } from 'react';

import Layout from 'antd/lib/layout';

import ResizablePanels from 'common/components/layout/ResizablePanels';
import { cn } from 'common/utils/cn';

import type { PageLayoutProps } from './layout-types';

const PageLayout = ({
  children,
  footer,
  header,
  sidebar,
  title,
  contentClassName,
}: PropsWithChildren<PageLayoutProps>): JSX.Element => {
  const content = (
    <>
      {header ? (
        <Layout.Header className="bg-card relative w-full flex items-center h-[80px] overflow-hidden px-6 leading-5 z-50 border-b-solid border-border border-b">
          {header}
        </Layout.Header>
      ) : null}
      <Layout.Content className={cn(`flex flex-col w-full p-0 z-40`, contentClassName)}>{children}</Layout.Content>
      {footer ? <Layout.Footer>Footer</Layout.Footer> : null}
    </>
  );

  return (
    <Layout className={`flex flex-col h-full w-full overflow-hidden bg-background`}>
      {sidebar ? (
        <ResizablePanels id={title} sidebar={sidebar}>
          {content}
        </ResizablePanels>
      ) : (
        content
      )}
    </Layout>
  );
};

export default PageLayout;
