import type { ForwardedRef, HTMLProps, ReactNode } from 'react';
import { forwardRef, useEffect, useRef } from 'react';

import { useResizeObserver } from 'usehooks-ts';

import { cn } from 'common/utils/cn';
import { mergeRefs } from 'common/utils/merge-refs';

type VerticalSizerProps = {
  readonly onHeightChange: (height: number) => void;
  readonly children: ReactNode;
} & HTMLProps<HTMLDivElement>;

export const VerticalSizer = forwardRef(
  ({ onHeightChange, children, className, ...props }: VerticalSizerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const innerRef = useRef<HTMLDivElement | null>(null);

    const { height } = useResizeObserver({ ref: innerRef });

    useEffect(() => {
      if (height !== undefined) {
        onHeightChange(height);
      }
    }, [height, onHeightChange]);

    return (
      <div className={cn('flex flex-1 flex-col h-full', className)} ref={mergeRefs(ref, innerRef)} {...props}>
        {children}
      </div>
    );
  },
);

VerticalSizer.displayName = 'VerticalSizer';
