import { useCallback } from 'react';

import type { InfiniteData } from '@tanstack/query-core';

import { LIST_KEY } from 'common/hooks/entity-manager/construct-list-query-key';
import type { CreateEntityManagerParams } from 'common/hooks/entity-manager/types';
import { queryClient } from 'common/query/query-constants';

type CreateUseDeleteEntityInCacheParams<T extends object> = Pick<CreateEntityManagerParams<T>, 'rootKey' | 'idField'>;

/**
 * Delete an item in the cache.
 * @param config - Configuration for identifying the list and the unique identifier.
 */
export const useDeleteEntityInCache = <T extends object>(config: CreateUseDeleteEntityInCacheParams<T>) => {
  const { rootKey, idField } = config;

  return useCallback(
    (id: string) => {
      queryClient.setQueriesData<InfiniteData<T[]>>(
        {
          queryKey: [rootKey, LIST_KEY],
        },
        (oldData) => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            pages: oldData.pages.map((page) => page.filter((item) => item[idField] !== id)),
          };
        },
      );

      queryClient.removeQueries({
        queryKey: [rootKey, id],
      });
    },
    [idField, rootKey],
  );
};
