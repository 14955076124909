// Page ids
export const ACCOUNT_PAGE_ID = 'account';
export const BILLING_PAGE_ID = 'billing';
export const BROWSE_PAGE_ID = 'browse';
export const DASH_PAGE_ID = 'dash';
export const EXTRACTORS_PAGE_ID = 'extractors';
export const HISTORY_PAGE_ID = 'history';
export const INTEGRATE_PAGE_ID = 'integrate';
export const CONFIG_HISTORY_PAGE_ID = `config-${HISTORY_PAGE_ID}`;
export const PORTAL_PAGE_ID = 'portal';
export const REPORTS_PAGE_ID = 'reports';
export const RESULTS_PAGE_ID = 'results';
export const SETTINGS_PAGE_ID = 'settings';
export const INPUT_SETTINGS_PAGE_ID = 'input-settings';
export const USER_MANAGEMENT_PAGE_ID = 'user-management';

export const V2_PAGE_ID = 'v2';

// Params
export const GUID = 'guid';
export const EXTRACTOR_GUID = 'extractorGuid';

// Match params
export const MATCH_EXTRACTOR_GUID = `:${EXTRACTOR_GUID}`;
export const MATCH_GUID = `:${GUID}`; // TODO: it's better to use ':guid' everywhere
export const MATCH_PORTAL_GUID = ':portalGuid';

// /browse
export const BROWSE_PAGE_URL = `/${BROWSE_PAGE_ID}`;
// /:extractorGuid
export const SHORT_EXTRACTOR_PAGE_URL = `/${MATCH_EXTRACTOR_GUID}`;
// /dash
export const DASH_PAGE_URL = `/${DASH_PAGE_ID}`;
// /dash/account
export const ACCOUNT_PAGE_URL = `${DASH_PAGE_URL}/${ACCOUNT_PAGE_ID}`;
// /dash/account/billing
export const ACCOUNT_BILLING_PAGE_URL = `${ACCOUNT_PAGE_URL}/${BILLING_PAGE_ID}`;
// /dash/account/settings
export const ACCOUNT_SETTINGS_PAGE_URL = `${ACCOUNT_PAGE_URL}/${SETTINGS_PAGE_ID}`;
// /dash/account/user-management
export const ACCOUNT_USER_MANAGEMENT_URL = `${ACCOUNT_PAGE_URL}/${USER_MANAGEMENT_PAGE_ID}`;
// /dash/extractors
export const EXTRACTORS_PAGE_URL = `${DASH_PAGE_URL}/${EXTRACTORS_PAGE_ID}`;
// /dash/portal
export const PORTALS_PAGE_URL = `${DASH_PAGE_URL}/${PORTAL_PAGE_ID}`;
// /dash/reports
export const REPORTS_PAGE_URL = `${DASH_PAGE_URL}/${REPORTS_PAGE_ID}`;
export const NEW_REPORT_PAGE_URL = `${DASH_PAGE_URL}/${REPORTS_PAGE_ID}/new`;
export const NEW_REPORT_SETTINGS_PAGE_URL = `${NEW_REPORT_PAGE_URL}/settings`;
// /dash/reports/:guid
export const REPORT_URL = `${REPORTS_PAGE_URL}/${MATCH_GUID}`;
// /results
export const RESULTS_PAGE_URL = `/${RESULTS_PAGE_ID}`;
