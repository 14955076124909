import type { JSX } from 'react';
import { useParams } from 'react-router-dom';

import { isPresent } from '@import-io/typeguards';

import { ActionsHeader } from 'common/components/layout/header/ActionsHeader';
import { ReportName } from 'features/reports/common/components/ReportName';
import { useReport } from 'features/reports/common/hooks/use-reports-hooks';
import type { ExtendedReport } from 'features/reports/common/reports-types';
import { useReportHeaderActions } from 'features/reports/layout/header/reports-header-hooks';

const ReportsHeader = (): JSX.Element | null => {
  const { guid } = useParams();
  const reportId: string = guid!;
  const { data } = useReport(reportId);
  const report = data ?? ({} as ExtendedReport);
  const actions = useReportHeaderActions(report);

  if (!isPresent(data)) {
    return null;
  }

  return (
    <ActionsHeader actions={actions}>
      <ReportName report={report} />
    </ActionsHeader>
  );
};

export default ReportsHeader;
