import type { JSX, PropsWithChildren } from 'react';

import type { PageSidebarProps } from 'common/components/layout/layout-types';
import SubscriptionWidget from 'features/user/subscription/components/SubscriptionWidget';

const PageSidebar = ({ children, title, showSubscription = false }: PropsWithChildren<PageSidebarProps>): JSX.Element => (
  <div className="w-full h-full pr-px">
    <div className="flex flex-col w-full h-full bg-sidebar shadow-with-border z-40 overflow-hidden">
      <div className="text-card w-full relative h-[80px] px-2 flex items-center justify-center text-xl font-bold font-sans border-b border-transparent border-b-gray-border-inverse border-solid">
        {title}
      </div>
      <div className="flex flex-col flex-grow-1 overflow-hidden w-full text-sidebar-foreground">{children}</div>
      {showSubscription ? <SubscriptionWidget /> : null}
    </div>
  </div>
);

export default PageSidebar;
