import { useParams } from 'react-router-dom';

import type { ResponseError } from '@import-io/js-sdk';
import { objectStoreApi, ObjectStoreQueryOperation, ObjectStoreQueryRequestBuilder } from '@import-io/js-sdk';
import { ObjectStoreQueryFilter } from '@import-io/js-sdk/models/object-store-query-filter';
import { isGuid, isPresent } from '@import-io/typeguards';
import type { ReportConfig } from '@import-io/types/report-types';
import { ReportType } from '@import-io/types/report-types';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query/src/types';
import { parseAsArrayOf, parseAsStringEnum } from 'nuqs';
import { toast } from 'sonner';

import { history } from 'app/app-history';
import { createEntityManager } from 'common/hooks/entity-manager/create-entity-manager';
import type { GetListParams } from 'common/hooks/entity-manager/types';
import { createUseListQueryParams } from 'common/hooks/use-list-query-params';
import { REPORTS_PAGE_URL } from 'common/routes/routes-constants';
import type { WithRequired } from 'common/types-utils';
import type { ExtendedReport } from 'features/reports/common/reports-types';
import { getReportHistoryUrl } from 'features/reports/common/reports-utils';

export const useReportsQueryParams = createUseListQueryParams<ExtendedReport>({
  filtersParser: {
    type: parseAsArrayOf(parseAsStringEnum<ReportType>([ReportType.CRAWL_REPORT, ReportType.CRAWL_DIFF]), ','),
  },
  prefix: 'r',
});

const getReportsList = ({ search, filter, sort, page, pageSize }: GetListParams<ExtendedReport>) => {
  const q = new ObjectStoreQueryRequestBuilder()
    .setSortBy(sort!.property)
    .setSortDesc(sort!.isDescending)
    .setPageLimit(pageSize ?? 30)
    .setPageNumber(page ?? 1)
    .setShowArchived(false)
    .setShowMine(true);

  if (Boolean(search)) {
    q.addFilter('name', new ObjectStoreQueryFilter('like', `%${search}%`));
  }

  if (Array.isArray(filter?.type)) {
    q.addFilter('type', new ObjectStoreQueryFilter(ObjectStoreQueryOperation.IN, filter.type));
  }

  return objectStoreApi.report.query(q.build());
};

const REPORTS_KEY = 'reports';
const reportsManager = createEntityManager<ExtendedReport>({
  rootKey: REPORTS_KEY,
  defaultListParams: {
    sort: {
      property: 'meta_created_at',
      isDescending: true,
    },
    filter: {
      type: null,
    },
  },
  searchField: 'name',
  idField: 'guid',
  crudConfig: {
    getList: getReportsList,
    getById: (id) => objectStoreApi.report.get(id),
    updateById: (id, value) => objectStoreApi.report.update(id, value),
    deleteById: async (id: string) => {
      await objectStoreApi.report.update(id, { archived: true });
    },
    create: <T extends WithRequired<Partial<ExtendedReport>, 'latestConfigId'>>(data: T) => objectStoreApi.report.create(data),
  },
});

export const useReportsList = reportsManager.useList;
export const useReport = reportsManager.useById;
export const useUpdateReport = reportsManager.useUpdateById;

export const useDeleteReport = () => {
  const selectedGuid = useParams()['guid']!;
  const { items } = useReportsList({});

  return reportsManager.useDeleteById({
    onSuccess: (_, { id }) => {
      if (selectedGuid === id) {
        const firstReportId = items.filter((report) => report.guid !== id)[0]?.guid;
        const url = isPresent(firstReportId) ? getReportHistoryUrl(firstReportId) : REPORTS_PAGE_URL;
        void toast.success('Report has been deleted');
        history.replace(url);
      }
    },
    onError: () => {
      void toast.error('Failed to delete report');
    },
  });
};

const REPORT_CONFIG_KEY = 'report-config';

export type CreateReportConfigPayload = Omit<ReportConfig, '_meta' | 'guid' | 'reportId'> & {
  reportId?: string; // have to make reportId optional due to backend API mess
};

const useCreateReportConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: CreateReportConfigPayload) => {
      return objectStoreApi.reportConfiguration.create(payload);
    },
    onSuccess: (resp: ReportConfig) => {
      queryClient.setQueryData([REPORT_CONFIG_KEY, resp.guid], resp);
    },
  });
};

const useUpdateReportConfig = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: Partial<ReportConfig> }) => {
      return objectStoreApi.reportConfiguration.update(id, data);
    },
    onSuccess: (resp, { id }) => {
      queryClient.setQueryData([REPORT_CONFIG_KEY, id], resp);
    },
  });
};

export const useReportConfig = (configId: string | undefined): UseQueryResult<ReportConfig | undefined | null, ResponseError> => {
  return useQuery<ReportConfig | undefined | null, ResponseError>({
    queryKey: [REPORT_CONFIG_KEY, configId],
    queryFn: () => (isGuid(configId) ? objectStoreApi.reportConfiguration.get(configId) : null),
    refetchOnMount: false,
  });
};

export type CreateOrUpdateReportPayload = {
  report: WithRequired<Partial<ExtendedReport>, 'name' | 'type' | 'extractorId'>;
  config?: CreateReportConfigPayload;
};

// wrapper function that wraps and hides backend API issues
// does the following in order:
// 1. Creates report config
// 2. Creates report and assigns created config to latestConfigId
// 3. Updates report config - linking it back to the created report
export const useCreateOrUpdateReport = (
  options: UseMutationOptions<ExtendedReport, ResponseError, CreateOrUpdateReportPayload> = {},
) => {
  const createReportConfig = useCreateReportConfig();
  const createReport = reportsManager.useCreate();
  const updateReport = useUpdateReport();
  const updateReportConfig = useUpdateReportConfig();
  const { resetAll: resetListQueryParams } = useReportsQueryParams();
  const { onSuccess, onError, ...otherOptions } = options;

  return useMutation<ExtendedReport, ResponseError, CreateOrUpdateReportPayload>({
    ...otherOptions,
    mutationFn: async ({ report, config }: CreateOrUpdateReportPayload) => {
      const isNew = !isPresent(report.guid);
      if (isNew) {
        const createdConfig = await createReportConfig.mutateAsync(config!);
        const createdReport = await createReport.mutateAsync({
          data: { ...report, latestConfigId: createdConfig.guid },
        });
        await updateReportConfig.mutateAsync({
          id: createdConfig.guid,
          data: {
            reportId: createdReport.guid,
          },
        });
        return createdReport;
      }

      const needToCreateConfig = isPresent(config);

      if (!needToCreateConfig) {
        const updatedReport = await updateReport.mutateAsync({
          id: report.guid!,
          data: report,
        });
        return updatedReport as Required<ExtendedReport>;
      }

      // update existing report
      const createdConfig = await createReportConfig.mutateAsync({
        ...config,
        reportId: report.guid!,
      });

      const updateReportData = { ...report, latestConfigId: createdConfig.guid };

      const updatedReport = await updateReport.mutateAsync({
        id: report.guid!,
        data: updateReportData,
      });
      return { ...(updatedReport ?? {}), ...updateReportData } as Required<ExtendedReport>;
    },
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context);
      const isNew = !isPresent(variables.report.guid);
      if (isNew) {
        resetListQueryParams();
        void toast.success('Report created successfully');
      }
    },
    onError: (e, variables, context) => {
      onError?.(e, variables, context);
      const isNew = !isPresent(variables.report.guid);
      if (isNew) {
        resetListQueryParams();
        void toast.error('Failed to create report');
      }
    },
  });
};
