import type { JSX } from 'react';
import { useCallback } from 'react';

import { InPlaceEdit } from 'common/components/InPlaceEdit';
import { useIsOwner } from 'common/hooks/use-is-owner';
import { useUpdateReport } from 'features/reports/common/hooks/use-reports-hooks';
import type { ExtendedReport } from 'features/reports/common/reports-types';

type ReportNameProps = {
  readonly report: ExtendedReport;
};

export const ReportName = ({ report }: ReportNameProps): JSX.Element => {
  const { name } = report;

  const reportId = report.guid;
  const isOwner = useIsOwner(report);

  const updateReport = useUpdateReport();

  const handleRenameReport = useCallback(
    (newName: string) => {
      updateReport.mutate({ id: reportId, data: { name: newName } });
    },
    [reportId, updateReport],
  );

  return (
    <InPlaceEdit
      className="text-foreground font-sans text-2xl font-medium"
      disabled={!isOwner}
      onEditEnd={handleRenameReport}
      value={name}
    />
  );
};
