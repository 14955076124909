import type { JSX, PropsWithChildren } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import type { ResizablePanelsProps } from 'common/components/layout/layout-types';

const minSize = 10;
const defaultSize = 15;
const maxSize = 60;

const ResizablePanels = ({
  children,
  id = 'resizable-panels',
  sidebar,
}: PropsWithChildren<ResizablePanelsProps>): JSX.Element => (
  <PanelGroup autoSaveId={id} direction="horizontal">
    <Panel className={`flex flex-col h-full min-w-60 z-40`} defaultSize={defaultSize} maxSize={maxSize} minSize={minSize}>
      {sidebar}
    </Panel>
    <PanelResizeHandle className="h-full relative z-50">
      <div className="h-full top-0 w-[20px] absolute -left-[10px] bg-transparent" />
    </PanelResizeHandle>
    <Panel className="flex flex-col h-full z-30">{children}</Panel>
  </PanelGroup>
);

export default ResizablePanels;
