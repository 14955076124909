import type { ForwardedRef, HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import type { DataListColumnComponent, DataListColumnConfig } from 'common/components/list/types';
import { cn } from 'common/utils/cn';

export type DataListItemProps<T> = {
  readonly item: T;
  readonly columns: DataListColumnConfig<T>[];
  readonly columnsWidths: number[];
} & HTMLAttributes<HTMLDivElement>;

// eslint-disable-next-line react/function-component-definition
function DataListItemInner<T>(
  { item, columns, className, columnsWidths, ...props }: DataListItemProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div {...props} className={cn('w-full bg-card rounded-md p-2 h-16 transition-none', className)} ref={ref}>
      {columns.map((column, colIndex) => {
        const Component: DataListColumnComponent<T> = column.component;
        const colWidth = columnsWidths[colIndex] ?? 0;
        return (
          <div
            className={cn('h-full w-full flex items-center overflow-hidden transition-none', column.className)}
            key={column.key}
          >
            <Component column={column} columnWidth={colWidth} componentProps={column.componentProps} item={item} />
          </div>
        );
      })}
    </div>
  );
}

export const DataListItem = forwardRef(DataListItemInner) as <T>(
  props: DataListItemProps<T> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof DataListItemInner>;
