import type { JSX } from 'react';

const Coin = (): JSX.Element => (
  <svg
    className="inline-block"
    viewBox="7.14 12.79 33.72 22.41"
    width={12}
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.54 21.755v4.49c0 4.73 7.37 8.56 16.46 8.56s16.46-3.84 16.46-8.56v-4.49" fill="#db9a16" />
    <ellipse cx="24" cy="21.755" fill="#ffc601" rx="16.46" ry="8.56" />
    <path
      d="M24 13.195c9.09 0 16.46 3.832 16.46 8.56s-7.268 8.56-16.46 8.56c-9.347 0-16.46-3.832-16.46-8.56s7.37-8.56 16.46-8.56z"
      fill="none"
      stroke="#303030"
      strokeMiterlimit="10"
      strokeWidth=".8"
    />
    <path
      d="M11.43 21.755c0-3.61 5.63-6.11 12.57-6.11"
      fill="none"
      stroke="#303030"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth=".8"
    />
    <path
      d="M8.11 24.005v-.01m31.78.01v-.01m-32.35-2.24v4.49c0 4.73 7.37 8.56 16.46 8.56s16.46-3.84 16.46-8.56v-4.49"
      fill="none"
      stroke="#303030"
      strokeMiterlimit="10"
      strokeWidth=".8"
    />
    <path
      d="M35.77 32.215v-4.329m-4.99 6.139v-4.382m-4.99 5.092v-4.359m-4.99 4.259v-4.32m-5 3.34v-4.346m-4.99 2.056v-4.29"
      fill="none"
      stroke="#303030"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth=".8"
    />
  </svg>
);

export default Coin;
