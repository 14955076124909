import type { HTMLAttributes, ReactNode } from 'react';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { isPresent } from '@import-io/typeguards';
import { elementScroll, useVirtualizer } from '@tanstack/react-virtual';
import Spin from 'antd/lib/spin';
import Tooltip from 'antd/lib/tooltip';
import SimpleBar from 'simplebar-react';
import { useResizeObserver } from 'usehooks-ts';

import { DEFAULT_COLUMN_SIZE } from 'common/components/list/constants';
import { DataListItem } from 'common/components/list/DataListItem';
import type { DataListColumnConfig } from 'common/components/list/types';
import { LoadingSpinner } from 'common/components/shadcn/loading-spinner';
import { VerticalSizer } from 'common/components/VerticalSizer';
import type { UseGridStylesParams } from 'common/hooks/use-grid-columns-styles';
import { useGridColumnsStyles } from 'common/hooks/use-grid-columns-styles';
import { cn } from 'common/utils/cn';

export type DataListImperativeHandle = {
  scrollToIndex: (index: number, smooth?: boolean) => void;
  scrollIndexIntoView: (index: number, smooth?: boolean) => void;
  nativeElement: HTMLDivElement | null;
};

export type DataListProps<T> = {
  readonly items: T[];
  readonly itemKey: keyof T;
  readonly listPaddingY?: number;
  readonly itemHeight?: number;
  readonly itemGap?: number;
  readonly columnsGap?: number;
  readonly hiddenColumns?: string[];
  readonly columns: DataListColumnConfig<T>[];
  readonly itemClassName?: string;
  readonly loading?: boolean;
  readonly allLoaded?: boolean;
  readonly onLoadMore?: () => void;
  readonly loadingNode?: ReactNode;
  readonly allLoadedNode?: ReactNode;
  readonly scrollElement?: HTMLElement | null;
  readonly hideHeader?: boolean;
} & HTMLAttributes<HTMLDivElement>;

// 2. Modify the Inner Component
// eslint-disable-next-line react/function-component-definition
function DataListInner<T>(
  {
    items,
    columns,
    itemKey,
    itemHeight = 64,
    itemGap = 8,
    listPaddingY = 8,
    columnsGap = 16,
    hiddenColumns = [],
    itemClassName,
    loading = false,
    allLoaded = true,
    hideHeader = false,
    onLoadMore,
    loadingNode,
    allLoadedNode,
    className,
    scrollElement,
    ...props
  }: DataListProps<T>,
  ref: React.Ref<DataListImperativeHandle>,
) {
  const listContainerRef = useRef<HTMLDivElement | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number>(0);

  const { width = 0 } = useResizeObserver({
    ref: parentRef,
    box: 'border-box',
  });

  const totalHeight = useMemo(() => {
    return items.length * (itemHeight + itemGap) + listPaddingY * 2;
  }, [items.length, itemHeight, listPaddingY, itemGap]);

  const rowVirtualizer = useVirtualizer({
    count: items.length + 1,
    getScrollElement: () => scrollElement ?? listContainerRef.current,
    scrollMargin: scrollElement ? 0 : listContainerRef.current?.offsetTop ?? 0,
    estimateSize: () => itemHeight,
    gap: itemGap,
    paddingStart: listPaddingY,
    paddingEnd: listPaddingY,
    overscan: 10,
    scrollToFn: elementScroll,
  });

  // 2. Merge Refs in useImperativeHandle
  useImperativeHandle(ref, () => ({
    scrollToIndex: (index: number, smooth: boolean = false) => {
      if (smooth) {
        rowVirtualizer.scrollToIndex(index);
        return;
      }
      (scrollElement ?? listContainerRef.current)?.scrollTo({
        top: index * (itemHeight + itemGap),
        behavior: 'instant',
      });
    },
    scrollIndexIntoView: (index: number, smooth: boolean = false) => {
      const container = scrollElement ?? listContainerRef.current;
      if (!container) return;

      // Calculate the top and bottom position of the target item
      const itemTop = index * (itemHeight + itemGap);
      const itemBottom = itemTop + itemHeight;

      // Get current scroll position and container height
      const scrollTop = container.scrollTop;
      const containerHeight = container.clientHeight ?? height;

      // Determine if the item is within the current viewport
      const isInView = itemTop >= scrollTop && itemBottom <= scrollTop + containerHeight;

      if (isInView) {
        // Item is already in view; do nothing
        return;
      } else if (itemTop < scrollTop) {
        // Item is above the viewport; scroll to align it to the top
        container.scrollTo({
          top: itemTop,
          behavior: smooth ? 'smooth' : 'instant', // You can change this to 'smooth' if you prefer smooth scrolling
        });
      } else {
        console.log('isBelow');
        // Item is below the viewport; scroll to align it to the bottom
        // Ensure that we don't scroll beyond the maximum scrollable area
        const maxScrollTop = container.scrollHeight - containerHeight;
        const desiredScrollTop = itemBottom - containerHeight;
        container.scrollTo({
          top: Math.min(desiredScrollTop, maxScrollTop),
          behavior: smooth ? 'smooth' : 'instant', // You can change this to 'smooth' if you prefer smooth scrolling
        });
      }
    },
    nativeElement: parentRef.current,
  }));

  const viewportItems = rowVirtualizer.getVirtualItems();

  useEffect(() => {
    if (!isPresent(onLoadMore)) {
      return;
    }
    const [lastItem] = [...viewportItems].reverse();

    if (!isPresent(lastItem)) {
      return;
    }

    if (lastItem.index >= items.length - 1 && !allLoaded && !loading) {
      onLoadMore?.();
    }
  }, [viewportItems, allLoaded, loading, onLoadMore, items.length]);

  const displayColumns = useMemo(() => {
    const hiddenSet = new Set(hiddenColumns);
    return columns.filter((col) => !hiddenSet.has(col.key));
  }, [columns, hiddenColumns]);

  const showHeader = useMemo(() => {
    return !Boolean(hideHeader) && displayColumns.some((col) => isPresent(col.title));
  }, [displayColumns, hideHeader]);

  const gridStylesParams: UseGridStylesParams = useMemo(() => {
    return {
      columns: [
        ...displayColumns.map((col) => {
          return { ...DEFAULT_COLUMN_SIZE, ...(col.size ?? {}) };
        }),
      ],
      width: width - columnsGap,
      gap: columnsGap,
    };
  }, [columnsGap, displayColumns, width]);

  const { styles: gridStyles, widths } = useGridColumnsStyles(gridStylesParams);

  const List = useMemo(() => {
    return (
      <div
        style={{
          height: `${totalHeight}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {viewportItems.map((virtualItem) => {
          const isLastRow = virtualItem.index > items.length - 1;
          const item = items[virtualItem.index]!;

          if (isLastRow && !loading && !isPresent(allLoadedNode)) {
            return <div key="loading" />;
          }

          if (isLastRow) {
            return (
              <div
                className={cn('w-full bg-card rounded-md p-2 h-16 overflow-hidden', itemClassName)}
                key="loading"
                style={{
                  position: 'absolute',
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                  maxWidth: `${width}px`,
                }}
              >
                {loading ? (
                  isPresent(loadingNode) ? (
                    loadingNode
                  ) : (
                    <div className={cn('h-full flex items-center gap-4 justify-center overflow-hidden text-primary')}>
                      <LoadingSpinner /> <span>Loading..</span>
                    </div>
                  )
                ) : isPresent(allLoadedNode) ? (
                  allLoadedNode
                ) : (
                  <div className={cn('h-full flex items-center gap-4 justify-center overflow-hidden')}>
                    <span>Nothing more to load</span>
                  </div>
                )}
              </div>
            );
          }

          return (
            <DataListItem<T>
              className={itemClassName}
              columns={displayColumns}
              columnsWidths={widths}
              item={item}
              key={item[itemKey] as string}
              style={{
                position: 'absolute',
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
                maxWidth: `${width}px`,
                ...gridStyles,
              }}
            />
          );
        })}
      </div>
    );
  }, [
    allLoadedNode,
    displayColumns,
    gridStyles,
    itemClassName,
    itemKey,
    items,
    loading,
    loadingNode,
    totalHeight,
    viewportItems,
    width,
    widths,
  ]);

  return (
    <div
      className={cn(
        'flex flex-col flex-1 relative',
        {
          'overflow-hidden': !isPresent(scrollElement),
        },
        className,
      )}
      ref={parentRef}
      {...props}
    >
      {showHeader ? (
        <div className="p-2 whitespace-nowrap sticky -top-6 z-40" style={gridStyles}>
          {displayColumns.map(({ key, title }) => {
            return (
              <div className="overflow-hidden overflow-ellipsis" key={key}>
                <Tooltip title={title}>{title}</Tooltip>
              </div>
            );
          })}
        </div>
      ) : null}

      <div className="flex flex-col flex-1 w-full h-full">
        {isPresent(scrollElement) ? (
          List
        ) : (
          <VerticalSizer className="overflow-visible" onHeightChange={setHeight}>
            <SimpleBar scrollableNodeProps={{ ref: listContainerRef }} style={{ height: `${height}px` }}>
              {List}
            </SimpleBar>
          </VerticalSizer>
        )}
      </div>
    </div>
  );
}

export const DataList = React.forwardRef(DataListInner) as <T>(
  props: DataListProps<T> & React.RefAttributes<DataListImperativeHandle>,
) => React.ReactElement | null;
