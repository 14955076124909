/* eslint-disable */
// @ts-nocheck

'use client';

import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return <Sonner className="toaster group" theme={theme as ToasterProps['theme']} richColors {...props} />;
};

export { Toaster };
