import { useEffect, useMemo } from 'react';

import { objectStoreApi, ObjectStoreQueryRequestBuilder } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { CrawlRun } from '@import-io/types';
import { CrawlRunState } from '@import-io/types';
import type { InfiniteData, QueryKey } from '@tanstack/query-core';
import { useInfiniteQuery, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { maxBy } from 'lodash';

import { deduplicateBy } from 'common/utils/deduplicate-by';

export const CRAWL_RUNS_LIST_KEY = 'crawlRuns';
export const LATEST_CRAWL_RUN_KEY = 'latestCrawlRun';
export const CRAWL_RUN_KEY = 'crawlRun';

const PAGE_SIZE = 30;

// get paginated data for crawl runs list
export const useCrawlRuns = (extractorId: string) => {
  return useInfiniteQuery<CrawlRun[], Error, InfiniteData<CrawlRun[]>, QueryKey, number>({
    queryKey: [CRAWL_RUNS_LIST_KEY, extractorId],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) => {
      const query = new ObjectStoreQueryRequestBuilder()
        .setPageLimit(PAGE_SIZE)
        .setPageNumber(pageParam)
        .setSortDesc(true)
        .addEqFilter('extractorId', extractorId)
        .build();
      return objectStoreApi.crawlRun.query(query).then((resp) => {
        return resp;
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length === PAGE_SIZE ? allPages.length + 1 : undefined;
    },
    enabled: isPresent(extractorId),
  });
};

// Poll the first item of the list every 5 seconds to detect new crawl runs
export const useLatestCrawlRun = (extractorId?: string) => {
  return useQuery<CrawlRun | null, Error>({
    queryKey: [LATEST_CRAWL_RUN_KEY, extractorId],
    queryFn: async () => {
      const query = new ObjectStoreQueryRequestBuilder()
        .setPageLimit(1)
        .setPageNumber(1)
        .setSortDesc(true)
        .addEqFilter('extractorId', extractorId!)
        .build();
      const results = await objectStoreApi.crawlRun.query(query);
      return results[0] ?? null;
    },
    enabled: isPresent(extractorId),
    refetchInterval: 5000,
  });
};

const usePollCrawlRunsByIds = (crawlRunsIds: string[]) => {
  return useQueries({
    queries: crawlRunsIds.map((guid) => {
      return {
        queryKey: [CRAWL_RUN_KEY, guid],
        queryFn: () => {
          return objectStoreApi.crawlRun.get(guid!) as Promise<CrawlRun>;
        },
        enabled: isPresent(guid),
        refetchInterval: 1000,
      };
    }),
    combine: (results) => {
      return {
        data: results.map((result) => result.data).filter(isPresent),
        pending: results.some((result) => result.isPending),
      };
    },
  });
};

const ACTIVE_CRAWL_RUN_STATES: Set<CrawlRunState> = new Set<CrawlRunState>([
  CrawlRunState.PENDING,
  CrawlRunState.STARTED,
  CrawlRunState.COLLATING,
]);

export const useCrawlRunsList = (extractorId: string, onCrawlRunAdded?: (crawlRun: CrawlRun) => void) => {
  const queryClient = useQueryClient();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = useCrawlRuns(extractorId);
  const latestQuery = useLatestCrawlRun(extractorId);
  const crawlRuns: CrawlRun[] = useMemo(() => {
    return deduplicateBy(data?.pages.flat() ?? [], (item) => item.guid);
  }, [data]);

  const activeCrawlRunsIds = useMemo(() => {
    return crawlRuns.filter((cr) => ACTIVE_CRAWL_RUN_STATES.has(cr.state)).map((cr) => cr.guid);
  }, [crawlRuns]);

  const activeCrawlRunsQueries = usePollCrawlRunsByIds(activeCrawlRunsIds);

  const crawlRunAdded = isPresent(latestQuery.data) && (crawlRuns.length === 0 || latestQuery.data.guid !== crawlRuns[0]?.guid);

  useEffect(() => {
    const latestCrawlRun = latestQuery.data;
    if (!crawlRunAdded || !isPresent(latestCrawlRun)) {
      return;
    }
    // Update the crawl runs list
    queryClient.setQueryData<InfiniteData<CrawlRun[]>>([CRAWL_RUNS_LIST_KEY, extractorId], (oldData) => {
      if (!isPresent(oldData)) {
        return oldData;
      }
      const [firstPage = []] = oldData.pages;
      return {
        ...oldData,
        pages: [[latestCrawlRun, ...firstPage]],
        pageParams: oldData.pageParams.slice(0, 1),
      };
    });
    void queryClient.invalidateQueries({
      queryKey: [CRAWL_RUNS_LIST_KEY, extractorId],
      refetchType: 'active',
    });
    onCrawlRunAdded?.(latestCrawlRun);
  }, [crawlRunAdded, extractorId, queryClient, latestQuery.data, onCrawlRunAdded]);

  // update active CrawlRuns
  useEffect(() => {
    if (activeCrawlRunsQueries.data.length === 0) {
      return;
    }
    const activeIdsSet = new Set(activeCrawlRunsQueries.data.map((crawlRun) => crawlRun.guid));
    const activeById: Record<string, CrawlRun> = activeCrawlRunsQueries.data.reduce((acc, crawlRun) => {
      acc[crawlRun.guid] = crawlRun;
      return acc;
    }, {});
    queryClient.setQueryData<InfiniteData<CrawlRun[]>>([CRAWL_RUNS_LIST_KEY, extractorId], (oldData) => {
      if (!oldData) return oldData;
      const newPages = oldData.pages.map((page) =>
        page.map((crawlRun) => {
          if (activeIdsSet.has(crawlRun.guid)) {
            return activeById[crawlRun.guid]!;
          }
          return crawlRun;
        }),
      );
      return {
        ...oldData,
        pages: newPages,
      };
    });

    const latestRun = maxBy(activeCrawlRunsQueries.data, (crawlRun) => crawlRun._meta?.creationTimestamp);
    const latestRunIndex = crawlRuns.findIndex((crawlRun) => latestRun?.guid === crawlRun.guid);
    if (latestRunIndex === 0) {
      queryClient.setQueryData<CrawlRun>([LATEST_CRAWL_RUN_KEY, extractorId], latestRun);
    }
  }, [crawlRuns, activeCrawlRunsQueries.data, queryClient, extractorId]);

  return {
    crawlRuns: crawlRuns,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    isFetchingNextPage: isFetchingNextPage,
    isPending: isPending,
  };
};

export const useIsExtractorRunning = (extractorId: string): boolean => {
  const { data: latestCrawlRun } = useLatestCrawlRun(extractorId);

  return useMemo(() => {
    if (!isPresent(latestCrawlRun)) {
      return false;
    }
    return ACTIVE_CRAWL_RUN_STATES.has(latestCrawlRun.state) && latestCrawlRun.state !== CrawlRunState.COLLATING;
  }, [latestCrawlRun]);
};
