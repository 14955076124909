import type { HTMLAttributes } from 'react';

import { cn } from 'common/utils/cn';

export type SegmentConfig = {
  key: string;
  className?: string;
};

type SegmentedProgressProps = {
  readonly segments: SegmentConfig[];
  readonly total: number;
  readonly values: Record<string, number>;
  readonly rounded?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const OVERLAP_SIZE: number = 4;

export const SegmentedProgress = ({ rounded = true, segments, total, values, className, ...props }: SegmentedProgressProps) => {
  if (total === 0) {
    return (
      <div className={cn('w-full bg-gray-lighter h-2 rounded-full', { ['rounded-none']: !rounded }, className)} {...props} />
    );
  }

  return (
    <div
      className={cn(
        'w-full bg-gray-lighter h-2 rounded-full relative overflow-hidden flex items-center',
        { ['rounded-none']: !rounded },
        className,
      )}
      {...props}
    >
      {segments.map((segment, index) => {
        const value = values[segment.key] ?? 0;
        const percent = (value / total) * 100;
        const overlapExtend = value > 0 ? OVERLAP_SIZE * (index + 1) : 0;
        return (
          <div
            className={cn(
              'transition-all duration-500 ease h-full rounded-r-full -mr-2',
              { ['rounded-none']: !rounded },
              segment.className,
            )}
            key={segment.key}
            style={{ width: `calc(${percent}% + ${overlapExtend}px)`, zIndex: `${100 - index}` }}
          />
        );
      })}
    </div>
  );
};
