import type { JSX } from 'react';

import SubscriptionUpdateButton from 'features/user/subscription/components/SubscriptionUpdateButton';

import type { UpgradeMessageProps } from './disabled-feature-types';
import styles from './UpgradeMessage.module.less';

const buttonStyle = { marginTop: '15px' };

const UpgradeMessage = ({ message, showUpgrade = false }: UpgradeMessageProps): JSX.Element => {
  return (
    <div className={`full-width full-height flex align-center text-center flex-column justify-center`}>
      <h3>{message}</h3>
      {showUpgrade ? (
        <div>
          <h5 className={styles.title}>Would you like it to?</h5>
          <SubscriptionUpdateButton style={buttonStyle} />
        </div>
      ) : null}
    </div>
  );
};

export default UpgradeMessage;
