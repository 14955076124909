import type { KeyboardEvent, MouseEvent } from 'react';
import { useRef, useState } from 'react';

import CheckOutlined from '@ant-design/icons/CheckOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { isPresent } from '@import-io/typeguards';

import { Button } from 'common/components/shadcn/button';
import { cn } from 'common/utils/cn';

type InPlaceEditProps = {
  readonly className?: string;
  readonly buttonClassName?: string;
  readonly disabled?: boolean;
  readonly value: string;
  readonly saveOnBlur?: boolean;
  readonly onEditStart?: () => void;
  readonly onEditEnd?: (value: string) => void;
};

export const InPlaceEdit = ({
  value,
  saveOnBlur = false,
  disabled = false,
  className,
  buttonClassName,
  onEditStart,
  onEditEnd,
}: InPlaceEditProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const onContainerClick = () => {
    if (!isPresent(containerRef.current)) {
      return;
    }
    if (!isEditing) {
      containerRef.current.focus();
    }
  };

  const startRenaming = () => {
    if (disabled) {
      return;
    }
    setIsEditing(true);
    onEditStart?.();
  };

  const onRenameClick = (event: MouseEvent) => {
    event.stopPropagation();
    startRenaming();
  };

  const endRenaming = (save = true) => {
    if (!isEditing) {
      return;
    }
    setIsEditing(false);
    if (!isPresent(inputRef.current)) {
      return;
    }
    if (!isPresent(containerRef.current)) {
      return;
    }
    onEditEnd?.(save ? inputRef.current.value : value);
    containerRef.current.focus();
  };

  const onRenameSaveClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    endRenaming(true);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      endRenaming(false);
    }
    if (event.key === 'Enter') {
      if (isEditing) {
        endRenaming();
      } else {
        startRenaming();
      }
    }
  };

  const onInputBlur = () => {
    setTimeout(() => {
      endRenaming(saveOnBlur);
    });
  };

  const renderEditMode = () => {
    return (
      <div className="w-full flex items-center gap-2 overflow-hidden whitespace-nowrap">
        <input
          autoFocus
          className={cn(
            `focus:outline-0 w-full focus:border-0 block overflow-hidden overflow-ellipsis whitespace-nowrap outline-0 border-0 text-[length:inherit] font-sans bg-transparent text-inherit p-0`,
          )}
          defaultValue={value}
          onBlur={onInputBlur}
          onFocus={(e) => e.target.select()}
          ref={inputRef}
          type="text"
        />
        <Button
          className={cn('flex-shrink-0 text-foreground', buttonClassName)}
          onClick={onRenameSaveClick}
          onMouseDown={(event) => event.preventDefault()}
          size="icon"
          variant="outline"
        >
          <CheckOutlined />
        </Button>
      </div>
    );
  };

  const renderReadMode = () => {
    return (
      <div
        className={`flex flex-grow items-center gap-2 overflow-hidden whitespace-nowrap font-sans`}
        onDoubleClick={startRenaming}
      >
        <div className="w-full block overflow-hidden overflow-ellipsis whitespace-nowrap">{value}</div>
        {!disabled ? (
          <Button
            className={cn('group-hover:visible invisible flex-shrink-0 text-foreground', buttonClassName)}
            onClick={onRenameClick}
            size="icon"
            variant="outline"
          >
            <EditOutlined />
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <div
      autoFocus
      className={cn(
        '-m-2 p-2 flex-grow flex flex-shrink group overflow-hidden overflow-ellipsis leading-none rounded-md hover:outline focus-within:outline outline-gray-light-md outline-1 outline-offset-1 focus-visible:outline',
        {
          outline: isEditing,
        },
        className,
      )}
      onClick={onContainerClick}
      onKeyDown={handleKeyPress}
      ref={containerRef}
      tabIndex={0}
    >
      {isEditing ? renderEditMode() : renderReadMode()}
    </div>
  );
};
