import { isPresent } from '@import-io/typeguards';
import type { InfiniteData, QueryKey } from '@tanstack/query-core';
import { useMutation } from '@tanstack/react-query';

import { LIST_KEY } from 'common/hooks/entity-manager/construct-list-query-key';
import type { CreateEntityManagerParams, CrudConfig, RollbackFn, UseUpdateByIdFn } from 'common/hooks/entity-manager/types';
import { createUpdateEntityInCache } from 'common/hooks/entity-manager/update-entity-in-cache';
import { queryClient } from 'common/query/query-constants';

export type CreateUseUpdateByIdParams<T extends object> = {
  queryFn: CrudConfig<T>['updateById'];
} & Pick<CreateEntityManagerParams<T>, 'rootKey' | 'idField'>;

export const createUseUpdateById = <T extends object>(config: CreateUseUpdateByIdParams<T>): UseUpdateByIdFn<T> => {
  const { rootKey, queryFn } = config;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return function useUpdateById(options = {}) {
    const { onSuccess, onError, onMutate, ...other } = options;
    const updateInCache = createUpdateEntityInCache(config);

    return useMutation({
      ...other,
      mutationFn: ({ id, data }: { id: string; data: Partial<T> }) => {
        return isPresent(queryFn) ? queryFn(id, data) : Promise.reject('Not implemented');
      },
      onMutate: async (params) => {
        onMutate?.(params);
        const { id, data } = params;
        await queryClient.cancelQueries({
          queryKey: [rootKey, LIST_KEY],
        });

        // Return object containing rollback fn in case mutation fails
        return updateInCache({ id: id, data: data });
      },
      onSuccess: (result, params, context) => {
        onSuccess?.(result, params, context);
        const { id, data } = params;
        updateInCache({ id: id, data: data });
      },
      onError: (e, params, context: { rollback: RollbackFn }) => {
        onError?.(e, params, context);
        console.log('Update Error', context);
        context.rollback();
        console.error(e);
      },
    });
  };
};
