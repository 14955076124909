import type { JSX } from 'react';

import { LoadingSpinner } from 'common/components/shadcn/loading-spinner';

const PageLoader = (): JSX.Element => (
  <div className="w-full h-full flex items-center justify-center text-primary">
    <LoadingSpinner size="lg" />
  </div>
);

export default PageLoader;
