import { type ResponseError, runReportApi } from '@import-io/js-sdk';
import type { ReportRun } from '@import-io/types';
import { CrawlRunState } from '@import-io/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { UseMutationResult } from '@tanstack/react-query/src/types';
import { toast } from 'sonner';

import { LATEST_REPORT_RUN_KEY } from 'features/reports/history/hooks/use-report-runs-list';

export const useRunReport = (): UseMutationResult<
  { reportRunId: string; reportId: string },
  ResponseError,
  { reportId: string }
> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: { reportId: string }) => {
      const { reportId } = params;
      return runReportApi.run(reportId).then((resp) => {
        return { ...resp, reportId: reportId };
      });
    },
    onSuccess: (resp, params: { reportId: string }) => {
      void toast.success('Report run started successfully');
      queryClient.setQueryData<ReportRun>([LATEST_REPORT_RUN_KEY, params.reportId], () => {
        return {
          reportId: params.reportId,
          guid: resp.reportRunId,
          status: CrawlRunState.PENDING,
          _meta: {
            creationTimestamp: Date.now(),
          },
        } as ReportRun;
      });
    },
    onError: (e) => {
      void toast.error(`Failed to run report: ${(e.body as any).message}`);
    },
  });
};
