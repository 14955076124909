import type { CSSProperties } from 'react';
import { useMemo } from 'react';

export type UseGridStylesColumnConfig = {
  span: number;
  minWidth?: number;
  maxWidth?: number;
};

export type UseGridStylesParams = {
  columns: UseGridStylesColumnConfig[];
  width: number;
  gap?: number;
};

export type UseGridStylesResult = {
  styles: CSSProperties;
  widths: number[];
};

function calculateRelativeSizes(spans: number[], width: number, gap: number): number[] {
  const totalGapSize = gap * (spans.length - 1);
  const totalColsWidths = width - totalGapSize - 10;
  const totalSpan = spans.reduce((acc, span) => acc + span, 0);
  const pxPerSpan = totalColsWidths / totalSpan;

  return spans.map((span) => {
    return span * pxPerSpan;
  });
}

export const useGridColumnsStyles = ({ columns, width, gap = 0 }: UseGridStylesParams): UseGridStylesResult => {
  const totalSpan = useMemo(() => {
    return columns.reduce((acc, col) => acc + col.span, 0);
  }, [columns]);

  return useMemo(() => {
    if (totalSpan === 0 || width === 0 || columns.length === 0) {
      return {
        styles: { display: 'grid', gridTemplateColumns: '' },
        widths: new Array(columns.length).fill(0),
      }; // Return empty style if totalSpan or width is zero
    }

    if (columns.length === 1) {
      return {
        styles: { display: 'grid', gridTemplateColumns: '1fr' },
        widths: [width],
      }; // Return empty style if totalSpan or width is zero
    }

    const totalGapSize = gap * (columns.length - 1);
    const totalColsWidths = width - totalGapSize - 10;

    let leftoverWidth = totalColsWidths;
    let leftoverSpan = totalSpan;

    let colsWidth: number[] = [];
    for (let i = 0; i < columns.length; i++) {
      const col = columns[i]!;
      // Calculate how many pixels each span unit represents
      const pxPerSpan = leftoverWidth / leftoverSpan;
      const tgWidth = Math.min(col.maxWidth ?? Infinity, Math.max(col.span * pxPerSpan, col.minWidth ?? 0));

      leftoverWidth -= tgWidth;
      leftoverSpan -= col.span;

      colsWidth.push(tgWidth);
    }

    if (leftoverWidth > 0) {
      colsWidth[colsWidth.length - 1] += leftoverWidth;
    } else if (leftoverWidth < 0) {
      colsWidth = calculateRelativeSizes(
        columns.map(({ span }) => span),
        width,
        gap,
      );
    }

    // Generate the gridTemplateColumns by multiplying span units with pxPerSpan
    const gridTemplateColumns = colsWidth.map((w) => `${w}px`).join(' ');

    // Return the style object
    return {
      styles: {
        display: 'grid',
        gap: `${gap}px`,
        gridTemplateColumns: gridTemplateColumns,
      } satisfies CSSProperties,
      widths: colsWidth,
    } satisfies UseGridStylesResult;
  }, [gap, totalSpan, width, columns]);
};
